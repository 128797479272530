/**
 * This is a reusable component for whatsapp functionality
 * mobile views of multiple headers
 */

import { whatsappMessageRender } from '@/utils/whatsappMessageRender';
import { WhatsAppIcon } from '@/assets/svgExports/WhatsAppIcon';
import { useSelector } from 'react-redux';
import { HeaderSectionListItem, MenuItem } from './index.styles';

const MobileWhatsAppComp = (props) => {
  const {
    storeData: {
      store_info,
      store_id,
      owner: { phone: registered_phone },
      display_phone,
    },
    userData,
  } = useSelector((state) => ({
    userData: state.userReducer.data,
    storeData: state.storeReducer.store,
  }));

  const onContactBtnClick = () => {
    whatsappMessageRender({
      userData,
      phoneNumber: display_phone || registered_phone || '',
      storeUrl: store_info?.store_url,
      storeId: store_id,
    });
  };

  return (
    <HeaderSectionListItem onClick={onContactBtnClick}>
      <WhatsAppIcon size={24} />
      <MenuItem secondaryColor={props.secondaryColor || '#000000'}>
        Contact on WhatsApp
      </MenuItem>
    </HeaderSectionListItem>
  );
};

export default MobileWhatsAppComp;
